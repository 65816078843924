import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { AllProductsView } from "../../components/CollectionView/index.js"
import Layout from "../../components/layouts"
import SEO from "../../components/seo"
import { useTranslationContext } from "../../context/TranslationContext.js"

const canonicals = {
  ["en-US"]: "/l/all-products/",
  ["de-DE"]: "/de/l/alle-produkte/",
}

const AllProducts = ({ data }) => {
  let { contentfulNavigationMenu: menu } = data
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "en-US",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return (
    <Layout pullUp>
      <SEO title={"All Products"} canonicals={canonicals} />
      <AllProductsView items={menu.items} />
    </Layout>
  )
}

export default AllProducts

export const query = graphql`
  {
    contentfulNavigationMenu(
      name: { eq: "All Products" }
      node_locale: { eq: "en-US" }
    ) {
      node_locale
      items {
        ... on ContentfulCategory {
          id
          name
          slug
          metaImage {
            file {
              url
            }
          }
          description {
            description
            childMarkdownRemark {
              html
            }
          }
          collections {
            id
            name
            slug
            showProductGrid
            imageSizes
            productPageType
            category {
              slug
            }
            description {
              description
              childMarkdownRemark {
                html
              }
            }
            ...CollectionProducts
            productGrid {
              ...CollectionProduct
              ...BeddingBundle
              ...Hero
              ...TileSection
            }
          }
        }
      }
    }
  }
`
